import React, { Component } from 'react';


class OpenHours extends Component {

    constructor(props) {
        super(props);
        this.daysOfWeek = ["sun", "mon", "tue", "wed", "thur", "fri", "sat"];
    }

    addOpenHours = (e) => {
        e.preventDefault();
        if (this.props.openhours) {
            return;
        }

        let openhours = {};
        this.daysOfWeek.forEach(day => {
            openhours[day] = [];
        })

        this.props.openhoursDidUpdate(openhours);
    }

    handleHoursChange = (type, dayStr, i) => {
        // type must be "open" or "close"
        return (e) => {
            let value = e.target.value;
            let { openhours } = this.props;
            let dayOpenHours = openhours[dayStr]
            let openElement = dayOpenHours[i];
            let newOpenElement = { ...openElement, [type]: value }
            let newDayOpenHours = [...dayOpenHours];
            newDayOpenHours[i] = newOpenElement;
            let newOpenHours = { ...openhours, [dayStr]: newDayOpenHours }
            this.props.openhoursDidUpdate(newOpenHours);
        }
    }
    
    addOpenHour = (dayStr) => {
        let { openhours } = this.props;
        let newElement = { open: "", close: ""};
    
        let newOpenHours = {
            ...openhours
        }
    
        newOpenHours[dayStr].push(newElement);

        this.props.openhoursDidUpdate(newOpenHours);
    }
    
    deleteOpenHour = (dayStr, i) => {
        let { openhours } = this.props;
        let dayOpenHours = [...openhours[dayStr]]
        dayOpenHours.splice(i, 1);
    
        let newOpenHours = {
            ...openhours,
            [dayStr]: dayOpenHours,
        }
    
        this.props.openhoursDidUpdate(newOpenHours);
    }


    render() {
        const { openhours } = this.props;

        if (!openhours) {
            return (
                <div>
                    <p><em>No open hours defined</em> <button className="btn" onClick={ this.addOpenHours }>Add</button></p>
                </div>
            )
        }

        return (
            <div>
            {
                this.daysOfWeek.map( dayStr => {
                let dayArray = openhours[dayStr];
                let dayDetails = dayArray.map((openElement, i) => {
                    return ( 
                    <div key={ i } className="row" style={{marginBottom: "0px"}}>
                        <div className="input-field col s2 push-s1" style={{marginTop: "0px"}}>
                            <label htmlFor="input.open">Open</label>
                            <input type="number" id="input.open" value={ openElement.open } onChange= { this.handleHoursChange("open", dayStr, i) } />
                        </div>
                        <div className="input-field col s2 push-s1" style={{marginTop: "0px"}}>
                        <label htmlFor="input.close">Close</label>
                        <input type="number" id="input.close" value={ openElement.close } onChange= { this.handleHoursChange("close", dayStr, i) } />
                        </div>
                        <div className="col s1 push-s1">
                        <a className="btn-floating btn-small red"><i className="material-icons" onClick={() => this.deleteOpenHour(dayStr, i)}>delete</i></a>
                        </div>
                    </div> 
                    ) 
                })
                return (
                    <div key={ dayStr }> 
                    <div className="row" style={{marginBottom: "0px", marginTop: "20px"}}>
                        <div className="col s2"><h6> { dayStr } </h6></div>
                        <div className="col s1"><a className="btn-floating btn-small green"><i className="material-icons" onClick={ () => this.addOpenHour(dayStr)}>add</i></a></div>
                    </div>
                        { dayDetails }
                    </div>
                )
                })
            }
            </div>
        )
    }
}
  
export default OpenHours;
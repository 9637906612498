import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createRestaurant } from '../../store/actions/restaurantActions';
import loading from '../../images/loading.gif';
import RestaurantForm from './RestaurantForm';

class RestaurantCreate extends Component {
  state = {
    loading: false,
    error: null,

    formData: {
      name: '',
      shortName: '',
      address: '',
      url: '',
      logoImage: '',
      image: '',
      rid: '',
      lat: '',
      lng: '',
      partySizeRanges: [{lower: 1, upper: 6}, {lower: 7, upper: 10}],
      openhours: {
        sun: [], // e.g. { open: "0800", close: "2300", key: 0}
        mon: [],
        tue: [],
        wed: [],
        thur: [],
        fri: [],
        sat: []
      }
    }
  }

  restaurantFormUpdated = (updates) => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...updates
      }
    })
  }

  partySizeRangesDidUpdate = () => {
  }

  openhoursDidUpdate = (openhours) => {
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({loading: true})

    let seedBeforeDate = new Date(this.state.formData.seedBeforeDate).toISOString();

    let restaurant = {
      shortName: this.state.formData.shortName, 
      detail: {
        name: this.state.formData.name,
        address: this.state.formData.address,
        logoImage: this.state.formData.logoImage,
        image: this.state.formData.image, 
        url: this.state.formData.url,
        openhours: this.state.formData.openhours,
      },
      partySizeRanges: this.state.formData.partySizeRanges,
      location: {
        lat: parseFloat(this.state.formData.lat),
        lng: parseFloat(this.state.formData.lng)
      },
      rid: this.state.formData.rid,
      seedBeforeDate: seedBeforeDate
    }

    this.props.createRestaurant(restaurant)
    .then(restaurantId => {
      this.setState({loading: false});
      if (restaurantId) {
        this.props.history.push('/restaurant/' + restaurantId);
      } 
    })
  }

  render() {

    if (!this.props.isLoggedIn) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="container">
        <form className="white" onSubmit={this.handleSubmit}>
          <h4 className="grey-text text-darken-3">Create Restaurant</h4>
          
          <RestaurantForm formData={this.state.formData} restaurantFormUpdated={this.restaurantFormUpdated} openhoursDidUpdate={this.openhoursDidUpdate} partySizeRangesDidUpdate={this.partySizeRangesDidUpdate} />

          <div className="row">
            <div className="input-field col l2 m6 s12">
              <button className="btn pink lighten-1 z-depth-0">Create</button>
              {
                this.state.loading ? <img src={loading} alt="Loading" width="30" height="30" style={{"margin-left": "12px", "verticalAlign": "middle"}} /> : null
              }
            </div>
            <span className="col l2 m6">
              <p className="red-text">{ this.props.error }</p>
            </span>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.session ? true : false,
    error: state.restaurant.createError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createRestaurant: (restaurant) => dispatch(createRestaurant(restaurant))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantCreate)

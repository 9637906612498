import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { editRestaurant } from '../../store/actions/restaurantActions';
import { uploadImage } from '../../store/actions/uploadActions';
import OpenHours from './OpenHours'
import { shortDate } from '../../helpers'
import loading from '../../images/loading.gif'
import 'materialize-css/dist/css/materialize.min.css'
import M from 'materialize-css/dist/js/materialize.min.js'
import RestaurantForm from './RestaurantForm';

class RestaurantEdit extends Component {
  state = {
    loading: false,
    restaurantNotFound: false,
    openhoursUpdated: false,
    partySizeRangesUpdated: false,

    formData: {
      name: '',
      shortName: '',
      address: '',
      url: '',
      logoImage: '',
      image: '',
      rid: '',
      lat: '',
      lng: '',
      openhours: {
        sun: [], // e.g. { open: "0800", close: "2300"}
        mon: [],
        tue: [],
        wed: [],
        thur: [],
        fri: [],
        sat: []
      }
    }
  }

  componentWillMount = () => {

    const {restaurant} = this.props;

    if (!restaurant) {
      this.setState({restaurantNotFound: true});
      return;
    }

    this.setState({
      formData: {
        name: restaurant.detail.name,
        shortName: restaurant.shortName,
        address: restaurant.detail.address,
        url: restaurant.detail.url,
        logoImage: restaurant.detail.logoImage || "",
        image: restaurant.detail.image,
        rid: restaurant.rid,
        lat: restaurant.location.coordinates[1],
        lng: restaurant.location.coordinates[0],
        seedBeforeDate: shortDate(new Date(restaurant.seedBeforeDate)),
        partySizeRanges: restaurant.partySizeRanges,
        openhours: restaurant.detail.openhours
      }
    });
  }

  restaurantFormUpdated = (updates) => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...updates
      }
    })
  }

  openhoursDidUpdate = (openhours) => {
    this.setState({openhoursUpdated: true});
  }

  partySizeRangesDidUpdate = (partySizeRanges) => {
    this.setState({partySizeRangesUpdated: true})
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({loading: true});

    let restaurant = this.props.restaurant;

    let update = { detail: {} };
    
    if (this.state.formData.name !== restaurant.detail.name) {
      update.detail.name = this.state.formData.name;
    }

    if (this.state.formData.shortName !== restaurant.detail.shortName) {
      update.shortName = this.state.formData.shortName;
    }

    if (this.state.formData.address !== restaurant.detail.address) {
      update.detail.address = this.state.formData.address;
    }

    if (this.state.formData.image !== restaurant.detail.image) {
      update.detail.image = this.state.formData.image;
    }

    if (this.state.formData.logoImage !== restaurant.detail.logoImage) {
      update.detail.logoImage = this.state.formData.logoImage;
    }

    if (this.state.formData.url !== restaurant.detail.url) {
      update.detail.url = this.state.formData.url;
    }

    if (this.state.formData.seedBeforeDate !== shortDate(new Date(restaurant.seedBeforeDate))) {
      update.seedBeforeDate = new Date(this.state.formData.seedBeforeDate).toISOString();  
    }
    
    // Location
    if (this.state.formData.lat !== restaurant.location.coordinates[1] || this.state.formData.lng !== restaurant.location.coordinates[0]) {
      update.location = {
        lat: this.state.formData.lat,
        lng: this.state.formData.lng
      }
    }

    // Party Size Ranges
    if (this.state.partySizeRangesUpdated === true) {
      update.partySizeRanges = this.state.formData.partySizeRanges;
    }

    // Openhours
    if (this.state.openhoursUpdated === true) {
      update.detail.openhours = this.state.formData.openhours;
    }

    if (this.state.formData.rid !== restaurant.rid) {
      update.rid = this.state.formData.rid;
    }

    this.props.editRestaurant(this.props.match.params.id, update)
    .then(() => {
      this.setState({loading: true});
      this.props.history.push('/');
    });
  }

  render() {

    if (!this.props.isLoggedIn) {
      return <Redirect to="/login" />;
    }

    if (this.state.restaurantNotFound) {
      return (
        <div className="container">
          <h3>Restaurant not found</h3>
        </div>
      )
    }

    const { name, shortName, address, url, logoImage, image, rid, seedBeforeDate, lat, lng } = this.state;

    return (
      <div className="container">
        <form className="white" onSubmit={this.handleSubmit}>

          <RestaurantForm formData={this.state.formData} restaurantFormUpdated={this.restaurantFormUpdated} openhoursDidUpdate={this.openhoursDidUpdate} partySizeRangesDidUpdate={this.partySizeRangesDidUpdate} />
          
          <div className="row">
            <div className="input-field col s12 m6">
              <button className="btn deep-orange lighten-1">Save</button>
              <Link to={ "/restaurant/" + this.props.match.params.id } className="btn deep-orange lighten-1" style={{"marginLeft": "12px"}}>Cancel</Link>
              {
                this.state.loading ? <img src={loading} alt="Loading" width="30" height="30" style={{"marginLeft": "12px", "verticalAlign": "middle"}} /> : null
              }
            </div>
            <span className="col s12 m6">
              <p className="red-text">{ this.props.error }</p>
            </span>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const filteredRestaurants = state.restaurant.restaurants.filter(restaurant => restaurant._id === id);
  const restaurant = filteredRestaurants[0];

  return {
    restaurant: restaurant,
    isLoggedIn: state.auth.session ? true : false,
    error: state.restaurant.editError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editRestaurant: (id, restaurant) => dispatch(editRestaurant(id, restaurant)),
    uploadImage: (file) => dispatch(uploadImage(file))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantEdit)

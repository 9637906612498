import authReducer from './authReducer';
import restaurantReducer from './restaurantReducer';
import userReducer from './userReducer';
import uploadReducer from './uploadReducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    auth: authReducer,
    restaurant: restaurantReducer,
    user: userReducer,
    upload: uploadReducer
})

export default rootReducer;

import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Info from './components/dashboard/Info';
import Dashboard from './components/dashboard/Dashboard';
import LogIn from './components/auth/LogIn';
import RestaurantDetails from './components/restaurants/RestaurantDetails';
import RestaurantCreate from './components/restaurants/RestaurantCreate';
import RestaurantEdit from './components/restaurants/RestaurantEdit';
import RestaurantArchive from './components/restaurants/RestaurantArchive';
import UserCreate from './components/users/UserCreate'


class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route path="/info" component={Info} />
            <Route path="/login" component={LogIn} />
            <Route exact path="/restaurant/create" component={RestaurantCreate} />
            <Route path="/restaurant/:id/edit" component={RestaurantEdit} />
            <Route path="/restaurant/:id/users/create" component={UserCreate} />
            <Route path="/restaurant/:id/archive" component={RestaurantArchive} />
            <Route path="/restaurant/:id" component={RestaurantDetails} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;

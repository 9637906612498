import axios from 'axios'

export const loginRequest = (creds) => {
    return (dispatch, getState) => {

        let baseUrl = process.env.REACT_APP_BASE_URL;
        let apiKey = process.env.REACT_APP_API_KEY;
        let path = "/auth";

        axios.post(baseUrl + path, {
            username: creds.username,
            password: creds.password
        }, {
            headers: { "x-api-key": apiKey }
        }).then(response => {
            if (response.data.result === "OK" && response.data.token) {
                console.log("auth ok!");
                localStorage.setItem("session", JSON.stringify(response.data));
                dispatch({ type: 'LOGIN_SUCCESS', session: response.data });
            } else {
                console.log("auth not ok");
                dispatch({ type: 'LOGIN_FAILED', err: "Unauthorised" });
            }
        }).catch(err => {
            console.log("Auth failed!");
            dispatch({ type: 'LOGIN_FAILED', err: err });
        });
    }
}

export const logoutRequest = () => {
    return (dispatch, getState) => {

        localStorage.setItem("session", null);

        const { token } = getState().auth.session;

        let baseUrl = process.env.REACT_APP_BASE_URL;
        let apiKey = process.env.REACT_APP_API_KEY;
        let path = "/logout";

        axios.get(baseUrl + path, {
            headers: { Authorization: "Bearer " + token, "x-api-key": apiKey  }
        }).then(() => {
            dispatch({ type: 'LOGOUT_SUCCESS' });
        }).catch(() => {
            dispatch({ type: 'LOGOUT_SUCCESS' });
        });
    }
}

export const unauthorised = () => {
    return (dispatch, getState) => {
        localStorage.setItem("session", null);
        dispatch({ type: 'LOGOUT_SUCCESS' });
    }
}

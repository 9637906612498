import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { loginRequest } from '../../store/actions/authActions'

class LogIn extends Component {
  state = {
    username: '',
    password: ''
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.loginRequest(this.state);
  }

  render() {

    const { authError, session } = this.props;

    if (session) {
      return  <Redirect to="/" />
    }

    return (
      <div className="container">
        <form className="white" onSubmit={this.handleSubmit}>
          <h5 className="grey-text text-darken-3">Sign In</h5>
          <div className="input-field">
            <label htmlFor="username">Username</label>
            <input type="email" id='username' onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <label htmlFor="password">Password</label>
            <input type="password" id='password' onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <button className="btn pink lighten-1 z-depth-0">Login</button>
          </div>
        </form>
        <span className="center">
          <p className="red-text">{ authError }</p>
        </span>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.auth.session,
    authError: state.auth.authError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginRequest: (creds) => dispatch(loginRequest(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogIn)
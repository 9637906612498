const initState = {
    uploading: false,
    uploadedLocation: null,
    uploadError: null
  }
  
  const uploadReducer = (state = initState, action) => {
  
    switch (action.type) {
      case 'UPLOADING_IMAGE':
        return { ...initState, uploading: true };

      case 'UPLOADED_IMAGE': 
        return { ...initState, uploadedLocation: action.location };

      case 'UPLOAD_IMAGE_FAILURE':
        return { ...initState, uploadError: action.error }
  
      default: 
        return state;
    }
  };
  
  export default uploadReducer;
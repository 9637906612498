import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import RestaurantSummary from './RestaurantSummary';
import { getRestaurants } from '../../store/actions/restaurantActions';

class RestaurantList extends Component {

    componentDidMount() {
        this.props.getRestaurants();
    }

    render() {

        const { restaurants } = this.props;

        return (
            <div className="project-list section">

                {
                    restaurants && restaurants.map(restaurant => {
                        return (
                            <RestaurantSummary restaurant={restaurant} key={restaurant._id} />
                        )
                    })

                }

                <div className="fixed-action-btn">
                    <Link to="/restaurant/create" className="btn-floating btn-large red">
                        <i className="large material-icons">add</i>
                    </Link>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        restaurants: state.restaurant.restaurants
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRestaurants: () => dispatch(getRestaurants())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantList);
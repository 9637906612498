import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class RestaurantSummary extends Component {
    render() {

        const { restaurant } = this.props;

        return (

            <div className="card restaurant-summary">
                <div className="card-content grey-text text-darken-3">
                    <Link to= { "/restaurant/" + restaurant._id } className="card-title">{ restaurant.detail.name }</Link>
                    <p>{ restaurant.detail.address }</p>
                </div>
            </div>
        )
    }
}

export default RestaurantSummary;
import axios from 'axios'
import { unauthorised } from './authActions';

export const getRestaurants = () => {
    return (dispatch, getState) => {

        const { token } = getState().auth.session;

        let baseUrl = process.env.REACT_APP_BASE_URL;
        let apiKey = process.env.REACT_APP_API_KEY;
        let path = "/restaurants";

        // expired token: 7bd91fd2fa6217b773ce91403f2c5517ac3d55cb84fb5383ea6f893476e1a748d6cd74bb8415e16cc09f349819bc42381f042896cf800a9be17443559558aa5c
        axios.get(baseUrl + path, {
            headers: { Authorization: "Bearer " + token, "x-api-key": apiKey }
        }).then(response => {
            const { restaurants } = response.data;
            dispatch({ type: 'RETRIEVED_RESTAURANTS', restaurants: restaurants});
        }).catch(error => {
            if (error.response && error.response.status == 401) {
                dispatch(unauthorised());
            } else {
                dispatch({ type: 'RETRIEVE_RESTAURANT_FAILURE' });
            }
        });
    };
}


export const createRestaurant = (restaurant) => {
    return (dispatch, getState) => {

        const { token } = getState().auth.session;
        const reqBody = { restaurant: restaurant };

        let baseUrl = process.env.REACT_APP_BASE_URL;
        let apiKey = process.env.REACT_APP_API_KEY;
        let path = "/restaurants";

        return axios.post(baseUrl + path, reqBody, {
            headers: { Authorization: "Bearer " + token, "x-api-key": apiKey }
        }).then(response => {
            if (response.status === 201) {
                restaurant._id = response.data.id;
                dispatch({ type: 'CREATE_RESTAURANT', restaurant: restaurant });
                return response.data.id;
            } else {
                let error = response.data.error;
                dispatch({ type: 'CREATE_RESTAURANT_FAILURE', error: error ? error : "Error creating restaurant" });
            }
        }).catch(error => {
            let errorMessage = error.response.data.error;
            dispatch({ type: 'CREATE_RESTAURANT_FAILURE', error: errorMessage ? errorMessage : "Error creating restaurant" });
        });        
    }
}

export const editRestaurant = (id, updates) => {
    return (dispatch, getState) => {

        const { token } = getState().auth.session;
        const reqBody = { restaurant: updates };

        let baseUrl = process.env.REACT_APP_BASE_URL;
        let apiKey = process.env.REACT_APP_API_KEY;
        let path = `/restaurants/${id}`;

        return axios.put(baseUrl + path, reqBody, {
            headers: { Authorization: "Bearer " + token, "x-api-key": apiKey }
        }).then(response => {
            if (response.status === 200) {
                dispatch({ type: 'EDIT_RESTAURANT_SUCCESS', updates: updates });
                return response.data.id;
            } else {
                let error = response.data.error;
                dispatch({ type: 'EDIT_RESTAURANT_FAILURE', error: error ? error : "Error updating restaurant" });
            }
        }).catch(error => {
            let errorMessage = error.response.data.error;
            dispatch({ type: 'EDIT_RESTAURANT_FAILURE', error: errorMessage ? errorMessage : "Error updating restaurant" });
        });        
    }
}

export const getArchived = (id, past, startDate, endDate) => {
    return (dispatch, getState) => {

        dispatch({ type: 'FETCHING_ARCHIVED'});

        const { token } = getState().auth.session;

        let baseUrl = process.env.REACT_APP_BASE_URL;
        let apiKey = process.env.REACT_APP_API_KEY;

        let path;

        if (past) {
            path = `/${id}/queue/archived?past=${past}`;
        } else if (startDate && endDate) {
            path = `/${id}/queue/archived?start=${startDate.toISOString()}&end=${endDate.toISOString()}`;
            
        } else {
            dispatch({ type: 'RETRIEVE_ARCHIVE_FAILURE' });
            return;
        }

        // expired token: 7bd91fd2fa6217b773ce91403f2c5517ac3d55cb84fb5383ea6f893476e1a748d6cd74bb8415e16cc09f349819bc42381f042896cf800a9be17443559558aa5c
        axios.get(baseUrl + path, {
            headers: { Authorization: "Bearer " + token, "x-api-key": apiKey }
        }).then(response => {
            const { archived } = response.data;
            dispatch({ type: 'RETRIEVED_ARCHIVED', archived: archived});
        }).catch(error => {
            if (error.response && error.response.status == 401) {
                dispatch(unauthorised());
            } else {
                dispatch({ type: 'RETRIEVE_ARCHIVE_FAILURE' });
            }
        });
    };
}

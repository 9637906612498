import React from 'react'
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { logoutRequest } from '../../store/actions/authActions'

const SignedInLinks = (props) => {
    return (
        <ul className="right">
            <li><NavLink to="/">Restaurants</NavLink></li>
            <li><a onClick={props.logoutRequest}>Log Out</a></li>
            {/* <li><NavLink to="/" className="btn btn-floating pink lighten-1">JF</NavLink></li> */}
        </ul>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        logoutRequest: () => dispatch(logoutRequest())
    }
}

export default connect(null, mapDispatchToProps)(SignedInLinks);
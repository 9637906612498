import React, { Component } from 'react';

class Dashboard extends Component {
    render() {

        let baseUrl = process.env.REACT_APP_BASE_URL;
        let environment = process.env.REACT_APP_SEATD_ENV;

        return (
            <div className="dashboard container">
                <p><strong>Environment: </strong> { environment } </p>
                <p><strong>Base URL: </strong> { baseUrl } </p>
            </div>
        )
    }
}


export default Dashboard;
import axios from 'axios'
import { unauthorised } from './authActions';

export const uploadImage = (file) => {
    return (dispatch, getState) => {
        dispatch({ type: 'UPLOADING_IMAGE'});

        const { token } = getState().auth.session;

        let baseUrl = process.env.REACT_APP_BASE_URL;
        let apiKey = process.env.REACT_APP_API_KEY;
        let path = `/image`;

        return axios.post(baseUrl + path, file, {
            headers: { Authorization: "Bearer " + token, "x-api-key": apiKey, "Content-Type": file.type }
        }).then(response => {
            const { location } = response.data;
            dispatch({ type: 'UPLOADED_IMAGE', location: location});
            return location;
        }).catch(error => {
            if (error.response && error.response.status == 401) {
                dispatch(unauthorised());
            } else {
                let err = error;
                if (error && error.response && error.response.data && error.response.data.friendlyError) {
                    err = error.response.data.friendlyError;
                }
                dispatch({ type: 'UPLOAD_IMAGE_FAILURE', error: err });
                throw err;
            }
        });
    };
}
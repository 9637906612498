import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk'
import rootReducer from './store/reducers/rootReducer';


const store = createStore(rootReducer, applyMiddleware(thunk));

const cachedSessionJson = localStorage.getItem("session");

if (cachedSessionJson) {
    const cachedSession = JSON.parse(cachedSessionJson);
    store.dispatch({ type: "RETRIEVED_AUTH_FROM_CACHE", session: cachedSession });
}

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

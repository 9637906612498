const initState = {
    createError: null
  }
  
  const userReducer = (state = initState, action) => {
  
    switch (action.type) {
  
      case 'CREATE_USER': 
        return { ...state, createError: null }
  
      case 'CREATE_USER_FAILURE':
        return { ...state, createError: action.error }
  
      default: 
        return state;
    }
  };
  
  export default userReducer;

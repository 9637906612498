import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import RestaurantList from '../restaurants/RestaurantList';

class Dashboard extends Component {
    render() {

        if (!this.props.isLoggedIn) {
            return <Redirect to="/login" />;
        }

        return (
            <div className="dashboard container">
                <div className="row">
                    <div className="col s12 m6">
                        <RestaurantList />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.session ? true : false
    }
}

export default connect(mapStateToProps)(Dashboard);
const initState = {
  restaurants: [],
  createError: null
}

const restaurantReducer = (state = initState, action) => {

  switch (action.type) {
    case 'RETRIEVED_RESTAURANTS':
      return { ...state, restaurants: action.restaurants };

    case 'CREATE_RESTAURANT': 
      return { ...state, restaurants: [...state.restaurants, action.restaurant], createError: null }

    case 'CREATE_RESTAURANT_FAILURE':
      return { ...state, createError: action.error }

    case 'EDIT_RESTAURANT_SUCCESS':
      return { ...state, restaurants: [] }

    case 'FETCHING_ARCHIVED': 
      return { ...state, archived: null }

    case 'RETRIEVED_ARCHIVED': 
      return { ...state, archived: action.archived }

    default: 
      return state;
  }
};

export default restaurantReducer;
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'

class RestaurantDetails extends Component {
  render() {

    const { id, restaurant } = this.props;

    if (!this.props.isLoggedIn) {
      return <Redirect to="/login" />;
    }

    if (!restaurant ) {
      return (
        <div className="container">
          <h3>Restaurant not found</h3>
        </div>
      )
    }

    return (
      <div className="section container">
        <div className="row">

          <div className="col s12 m6">
            <h3>{ restaurant.detail.name }</h3>
            <h6>{ id }</h6>
            <h6>{ restaurant.shortName }</h6>
          </div>

          <div className="col s12 m6">
            <div className="valign-wrapper right">
            <Link to={ "/restaurant/" + id + "/edit" } className="btn deep-orange lighten-1">
              <i className="material-icons left">edit</i> Edit
            </Link>
            <Link to= { "/restaurant/" + id + "/users/create" } className="btn deep-orange lighten-1">
                <i className="large material-icons left">person_add</i> Add User
            </Link>
            <Link to= { "/restaurant/" + id + "/archive" } className="btn deep-orange lighten-1">
                <i className="large material-icons left">access_time</i> View archive
            </Link>
            </div>
          </div>
        </div>
        
              
        <div className="row">
          <div className="col s12 m6 push-m6">
            <div className="row">
              <div className="col s12">
                <img src={ restaurant.detail.logoImage } className="responsive-img"></img> 
                <div className="caption">{ restaurant.detail.logoImage }</div>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <img src={ restaurant.detail.image } className="responsive-img"></img> 
                <div className="caption">{ restaurant.detail.image }</div>
              </div>
            </div>
          </div>
          <div className="col s12 m6 pull-m6">
            <p><strong>Address: </strong>{ restaurant.detail.address }</p>
            <p><strong>Website URL: </strong><a href={ restaurant.detail.url }>{ restaurant.detail.url }</a></p>
            <p><strong>RID: </strong>{ restaurant.rid } </p>
            <p><strong>Seed Before Date: </strong>{ restaurant.seedBeforeDate }</p>

                <h5>Party size ranges</h5>
                {
                  !restaurant.partySizeRanges ?
                    <p>Not defined</p> :
                    restaurant.partySizeRanges.map(range => {
                      return ( <span key={range.lower}>{range.lower}-{range.upper}</span> )
                    }).reduce((accu, elem) => {
                      return accu === null ? [elem] : [...accu, ', ', elem]
                    }, null)
                }

                <h5>Opening hours</h5>
                {
                  !restaurant.detail.openhours ?
                     <p>None defined</p> :

                  ["sun", "mon", "tue", "wed", "thur", "fri", "sat"].map( dayStr => {
                    let dayArray = restaurant.detail.openhours[dayStr];
                    let dayDetails = dayArray.map(openElement => {
                      return ( <li key={ openElement.open }>{ openElement.open } - { openElement.close }</li> )
                    })
                    return (
                      <div key={ dayStr }>
                        <strong> { dayStr } </strong> 
                        <ul style={{ marginTop: 0 }}>{ dayDetails }</ul>
                      </div>
                    )
                  })
                }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  const id = ownProps.match.params.id;
  const filteredRestaurants = state.restaurant.restaurants.filter(restaurant => restaurant._id === id);
  const restaurant = filteredRestaurants[0];

  return {
      isLoggedIn: state.auth.session ? true : false,
      id: id,
      restaurant: restaurant 
  }
}

export default connect(mapStateToProps)(RestaurantDetails);
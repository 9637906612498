import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';
import seatd from "../../images/seatd.png";


const Navbar = (props) => {
    const links = props.isLoggedIn ? <SignedInLinks /> : <SignedOutLinks />;

    return (
        <nav className="nav-wrapper grey darken-3">
            <div className="container">
                <Link to="/" className="brand-logo">
                <img src={seatd} width="45" height="45" style={{marginRight: "12px",verticalAlign: "middle", marginBottom: "8px"}} />
                Seatd Dashboard
                </Link>
                { links }
            </div>
        </nav>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.session ? true : false
    }
}

export default connect(mapStateToProps)(Navbar)
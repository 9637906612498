import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createUser } from '../../store/actions/userActions';
import loading from '../../images/loading.gif';

class UserCreate extends Component {
  state = {
        loading: false,

        username: '',
        password: '',
        confirm_password: '',
        formValidationError: null
    }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
      formValidationError: null
    })
  }


  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({loading: true});

    if (this.state.password !== this.state.confirm_password) {
        this.setState({
            formValidationError: "Passwords do not match"
        });
        return;
    }

    const restaurantId = this.props.match.params.id;
    console.log(restaurantId);

    let user = {
        username: this.state.username,
        password: this.state.password
    }

    this.props.createUser(user, restaurantId)
    .then(userId => {
      this.setState({loading: false});
      if (userId) {
        this.props.history.push('/restaurant/' + restaurantId);
      } 
    })
  }

  render() {

    if (!this.props.isLoggedIn) {
      return <Redirect to="/login" />;
    }

    const restaurantId = this.props.match.params.id;
    const filteredRestaurants = this.props.restaurants.filter(restaurant => restaurant._id === restaurantId);
    const restaurant = filteredRestaurants[0];

    return (
      <div className="container">
        <form className="white" onSubmit={this.handleSubmit}>
          <h4 className="grey-text text-darken-3">Create User</h4>
          <h5 className="grey-text text-darken-1">{ restaurant && restaurant.detail && restaurant.detail.name ? restaurant.detail.name : restaurantId  }</h5>
          <div className="input-field">
            <label htmlFor="username">Username</label>
            <input type="text" id='username' onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <label htmlFor="password">Password</label>
            <input type="password" id='password' onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <label htmlFor="confirm_password">Confirm Password</label>
            <input type="password" id='confirm_password' onChange={this.handleChange} />
          </div>
          
          <div className="row">
            <div className="input-field col s12 m6">
              <button className="btn pink lighten-1 z-depth-0">Create</button>
              {
                this.state.loading ? <img src={loading} alt="Loading" width="30" height="30" style={{"margin-left": "12px", "verticalAlign": "middle"}} /> : null
              }
            </div>
            <span className="col s12 m6">
              <p className="red-text">{ this.state.formValidationError ? this.state.formValidationError :  this.props.error }</p>
            </span>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.session ? true : false,
    restaurants: state.restaurant.restaurants,
    error: state.user.createError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (user, restaurantId) => dispatch(createUser(user, restaurantId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate)

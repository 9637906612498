import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getArchived } from '../../store/actions/restaurantActions';
import { Select, DatePicker } from 'react-materialize';
import { Redirect, Link } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js';


class RestaurantArchive extends Component {

    state = {
        dateRange: "1w",
        dateStart: null,
        dateEnd: null
    };

    componentWillMount() {
        this.props.getArchived(this.props.match.params.id, this.state.dateRange);
    }

    handleSelectDateRange = (e) => {
        let past = e.target.value;
        this.setState({
            dateRange: past
        })

        if (past === "custom") {
            this.openStartPicker();
        } else {
            this.props.getArchived(this.props.match.params.id, past);
        }  
    }

    handleRangeStartChanged = (date) => {
        this.setState({
            dateStart: date
        })
        this.openEndPicker();
    }

    handleRangeEndChanged = (date) => {
        this.setState({
            dateEnd: date
        });

        if (this.state.dateRange === "custom") {
            this.props.getArchived(this.props.match.params.id, null, this.state.dateStart, date);
        }
    }

    openStartPicker = () => {
        let startPickerInput = document.getElementById("datepicker-start");
        let startPicker = M.Datepicker.getInstance(startPickerInput);
        startPicker.open();
    }

    openEndPicker = () => {
        let endPickerInput = document.getElementById("datepicker-end");
        let endPicker = M.Datepicker.getInstance(endPickerInput);
        endPicker.open();
    }
    
    render() {

        if (!this.props.isLoggedIn) {
            return <Redirect to="/login" />;
        }

        const { archived } = this.props;
        const { dateRange, dateStart, dateEnd } = this.state;

        return (
            <div className="container">
            
                <div className="row" style={{marginBottom: 0, marginTop: "1em"}} >
                    <div className="valign-wrapper right" style={{marginBottom: 0}}>
                        <Select value={this.state.dateRange} label="Date Range" onChange={this.handleSelectDateRange} style={{marginBottom: 0}}>
                            <option value="" disabled>Choose date range</option>
                            <option value="1d">1 day</option>
                            <option value="1w">1 week</option>
                            <option value="2w">4 weeks</option>
                            <option value="custom">Custom</option>
                        </Select>
                    </div>
                </div>

                <div className="row" style={{marginTop: 0, position: "relative", top: "-1rem"}}>
                    <div>
                        <DatePicker 
                            id="datepicker-start" 
                            style={{display: "none"}}
                            onChange={this.handleRangeStartChanged}
                            options={{format:'dd mmm yyyy', autoClose: true}} />
                        <DatePicker 
                            id="datepicker-end" 
                            style={{display: "none"}}
                            onChange={this.handleRangeEndChanged}
                            options={{format:'dd mmm yyyy', autoClose: true}} />
                    </div>
                    { (dateRange == "custom") ?
                    <div className="valign-wrapper right" style={{fontSize: "0.8em"}}>
                        {
                            dateStart ? dateStart.toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' }) : null
                        } - {
                            dateEnd ? dateEnd.toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' }) : null
                        }
                        <button className="btn-flat" style={{paddingLeft: "0.5em"}} onClick={this.openStartPicker}>
                            <i className="material-icons tiny">edit</i>
                        </button>
                    </div> : null }
                </div>

                <table className="responsive-table highlight striped">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Ticket No</th>
                            <th>Party Size</th>
                            <th>Contact Method</th>
                            <th>Status</th>
                            <th>FB User</th>
                            <th>Quoted Timestamp</th>
                            <th>Time Queued</th>
                            <th>Time Seated</th>
                            <th>Time Removed</th>
                        </tr>
                    </thead>

                    <tbody>

                {
                    archived && archived.map(patron => 
                        <tr key={ patron.id }>
                            <td>{ patron.name }</td>
                            <td>{ patron.ticketNo }</td>
                            <td>{ patron.partySize }</td>
                            <td>{ patron.contactMethod }</td>   
                            <td>
                                { patron.status.map((status, i) => 
                                    <span key={status + i}>{ status }, </span>
                                )}
                            </td>
                            <td>{ patron.fbUser }</td>
                            <td>{ patron.quotedTimestamp }</td>
                            <td>{ patron.timeQueued }</td>
                            <td>{ patron.timeSeated }</td>
                            <td>{ patron.timeRemoved }</td>
                        </tr>
                    )

                }

                </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.session ? true : false,
        archived: state.restaurant.archived
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getArchived: (id, dateRange, startDate, endDate) => dispatch(getArchived(id, dateRange, startDate, endDate))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantArchive);
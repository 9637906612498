const initState = {
  session: null,
  authError: null
}

const authReducer = (state = initState, action) => {

  switch (action.type) {
    case 'LOGIN_SUCCESS': 
      return {
        ...state,
        session: action.session,
        authError: null
      }

    case 'LOGIN_FAILED':
      return {
        ...state,
        session: null,
        authError: "Log in failed"
      }

    case 'LOGOUT_SUCCESS': 
      return {
        ...state,
        session: null
      }

    case 'RETRIEVED_AUTH_FROM_CACHE':
      return {
        ...state,
        session: action.session,
        authError: null
      }

    default: 
      return state
  }
};

export default authReducer;
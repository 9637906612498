import React, { Component } from 'react';
import { connect } from 'react-redux';
import { uploadImage } from '../../store/actions/uploadActions';
import OpenHours from './OpenHours'
import loading from '../../images/loading.gif'
import 'materialize-css/dist/css/materialize.min.css'
import M from 'materialize-css/dist/js/materialize.min.js'

class RestaurantForm extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        upload: {
            logoImage: {
                loading: false,
                error: null
            },
            image: {
                loading: false,
                error: null
            }
        }
    }

    componentDidMount = () => {
        M.updateTextFields();
    }

    handleChange = (e) => {
        this.props.restaurantFormUpdated({
            [e.target.id]: e.target.value
        });
    }

    selectedImageFile = (field) => (e) => {
        this.setState({
            upload: {
                ...this.state.upload,
                [field]: {
                    loading: true,
                    error: null
                }
            }
        })
        this.props.uploadImage(e.target.files[0])
        .then(location => {
            this.setState({
                upload: {
                    ...this.state.upload,
                    [field]: {
                        loading: false,
                        error: null
                    }
                }
            });
            this.props.restaurantFormUpdated({
                [field]: location,
            });
        })
        .catch(error => {
            this.setState({
                upload: { 
                    ...this.state.upload,
                    [field]: {
                        loading: false,
                        error: error
                    }
                }
            })
        });
    }

    openhoursDidUpdate = (openhours) => {
        this.props.restaurantFormUpdated({
            openhours
        });
        this.props.openhoursDidUpdate();
    }

    addPartySizeRange = (e) => {
      e.preventDefault();
      let partySizeRanges = this.props.formData.partySizeRanges;
      partySizeRanges.push({lower: "", upper: ""});
      this.props.restaurantFormUpdated({
        partySizeRanges
      });
      this.props.partySizeRangesDidUpdate();
    }

    handlePartySizeRangeChange = (upperOrLower, i) => {
      return (e) => {
        e.preventDefault();
        let partySizeRanges = this.props.formData.partySizeRanges;
        partySizeRanges[i][upperOrLower] = e.target.value;
        this.props.restaurantFormUpdated({
          partySizeRanges
        });
        this.props.partySizeRangesDidUpdate();
      }
    }

    deletePartySizeRange = (i) => {
      return (e) => {
        e.preventDefault();
        let partySizeRanges = this.props.formData.partySizeRanges;
        partySizeRanges.splice(i, 1);
        this.props.restaurantFormUpdated({
          partySizeRanges
        });
        this.props.partySizeRangesDidUpdate();
      }
    }

  render() {

    const { name, shortName, address, url, logoImage, image, rid, seedBeforeDate, lat, lng, partySizeRanges } = this.props.formData;

    return (
        <div>
          <div className="input-field">
            <label htmlFor="name">Name</label>
            <input type="text" id='name' onChange={this.handleChange} value={name}/>
          </div>
          <div className="input-field">
            <label htmlFor="shortName">Short Name</label>
            <input type="text" id='shortName' onChange={this.handleChange} value={shortName} placeholder="e.g. 'seatd-demo'" />
          </div>
          <div className="input-field">
            <label htmlFor="address">Address</label>
            <input type="text" id='address' onChange={this.handleChange} value={address} />
          </div>
          <div className="input-field">
            <label htmlFor="url">Website URL</label>
            <input type="text" id='url' onChange={this.handleChange} value={url} />
          </div>
          <div className="input-field" style={{paddingBottom: "20px"}}>
            <label htmlFor="logoImage">Logo Image URL</label>
            <input type="text" id='logoImage' onChange={this.handleChange} value={logoImage} />
            <input type="file" id='logoImageFile' onChange={this.selectedImageFile("logoImage")} />
            {
              this.state.upload.logoImage.loading ? <img src={loading} alt="Uploading" width="30" height="30" style={{"marginLeft": "12px", "verticalAlign": "middle"}} /> : null
            }
            {
              this.state.upload.logoImage.error ? <span className="red-text">Upload failed: {this.state.upload.logoImage.error}</span> : null
            }
          </div>
          <div className="input-field" style={{paddingBottom: "20px"}}>
            <label htmlFor="image">Image URL</label>
            <input type="text" id='image' onChange={this.handleChange} value={image} />
            <input type="file" id='imageFile' onChange={this.selectedImageFile("image")} />
            {
              this.state.upload.image.loading ? <img src={loading} alt="Uploading" width="30" height="30" style={{"marginLeft": "12px", "verticalAlign": "middle"}} /> : null
            }
            {
              this.state.upload.image.error ? <span className="red-text">Upload failed: {this.state.upload.image.error}</span> : null
            }
          </div>
          <div className="input-field">
            <label htmlFor="rid">RID</label>
            <input type="text" id='rid' onChange={this.handleChange} value={rid ? rid : ""} />
          </div>
          <div className="input-field">
            <label htmlFor="seedBeforeDate">Seed Before Date</label>
            <input type="date" id='seedBeforeDate' onChange={this.handleChange} value={seedBeforeDate} />
          </div>
          <div className="row">
            <div className="input-field col s4">
              <label htmlFor="lat">Lat</label>
              <input type="text" id='lat' onChange={this.handleChange} value={lat} />
            </div>
            <div className="input-field col s4">
              <label htmlFor="lng">Lng</label>
              <input type="text" id='lng' onChange={this.handleChange} value={lng} />
            </div>
          </div>

          <h5>Party Size Ranges <a className="btn-floating btn-small green"><i className="material-icons" onClick={ this.addPartySizeRange }>add</i></a></h5>
          <div>
          {
            
            partySizeRanges.map((range, i) => {
              return ( 
                <div key={ i } className="row" style={{marginBottom: "0px"}}>
                  <div className="input-field col s2 push-s1" style={{marginTop: "0px"}}>
                    <label htmlFor="input.lower">Lower</label>
                    <input type="number" id="input.lower" value={ range.lower } onChange= { this.handlePartySizeRangeChange("lower", i) } />
                  </div>
                  <div className="input-field col s2 push-s1" style={{marginTop: "0px"}}>
                    <label htmlFor="input.upper">Upper</label>
                    <input type="number" id="input.upper" value={ range.upper } onChange= { this.handlePartySizeRangeChange("upper", i) } />
                  </div>
                  <div className="col s1 push-s1">
                      <a className="btn-floating btn-small red"><i className="material-icons" onClick={this.deletePartySizeRange(i)}>delete</i></a>
                  </div>
                </div>
            )
          })
        }
        </div>

          <h5>Opening hours</h5>
          <OpenHours openhours={this.props.formData.openhours} openhoursDidUpdate={this.openhoursDidUpdate} />
        </div>
    )
  }
}


const mapDispatchToProps = (dispatch) => {
    return {
        uploadImage: (file) => dispatch(uploadImage(file))
    }
}

export default connect(null, mapDispatchToProps)(RestaurantForm)

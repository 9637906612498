import axios from 'axios'

export const createUser = (user, restaurantId) => {
    console.log(restaurantId);
    return (dispatch, getState) => {

        const { token } = getState().auth.session;
        const reqBody = { 
            username: user.username,
            password: user.password,
            restaurantId: restaurantId
         };

         console.log(reqBody);

        let baseUrl = process.env.REACT_APP_BASE_URL;
        let apiKey = process.env.REACT_APP_API_KEY;
        let path = "/users";

        return axios.post(baseUrl + path, reqBody, {
            headers: { Authorization: "Bearer " + token, "x-api-key": apiKey  }
        }).then(response => {
            if (response.status === 201) {
                user._id = response.data.id;
                dispatch({ type: 'CREATE_USER', user: user });
                return response.data.id;
            } else {
                let error = response.data.error;
                dispatch({ type: 'CREATE_USER_FAILURE', error: error ? error : "Error creating user" });
            }
        }).catch(error => {
            let errorMessage = error.response.data.error;
            dispatch({ type: 'CREATE_USER_FAILURE', error: errorMessage ? errorMessage : "Error creating user" });
        });

        
    }
}
